/* Filter controls */
@layer components {
  .mobile-filter-toggle {
    @apply button h-10 w-10 min-w-40 min-h-40 m-0 ml-1 p-0 flex items-center justify-center grow-0 text-blue;
  }

  .mobile-filter-toggle:hover,
  .mobile-filter-toggle:focus,
  .mobile-filter-toggle:active {
    @apply text-black;
  }

  .mobile-filter-toggle[aria-expanded="true"],
  .mobile-filter-toggle[aria-expanded="true"]:hover,
  .mobile-filter-toggle[aria-expanded="true"]:focus,
  .mobile-filter-toggle[aria-expanded="true"]:active {
    @apply bg-yellow border-yellow text-black;
  }

  #filter-create-links {
    @apply hidden;
  }

  .expanded > #filter-create-links {
    @apply block;
  }

  .filter-fields-body input {
    @apply bg-white;
  }

  .filter-fields-body select.input {
    @apply text-blue;
  }

  .filter-fields-body .field:not(:last-child) {
    @apply mb-1;
  }

  .scroll-to-top button {
    @apply opacity-0;

    transition: 0.1s opacity ease-in-out;
  }

  .scroll-to-top button.visible {
    @apply opacity-100;
  }

  .filter-fields-body .tag-block input[type="checkbox"] {
    @apply w-full h-full absolute left-0 top-0 right-0 bottom-0 cursor-pointer border-none rounded-md;

    min-height: unset;
  }
  .filter-fields-body .tag-block input[type="checkbox"] + label {
    @apply relative w-full cursor-pointer text-center;

    pointer-events: all;
    white-space: unset;
    overflow: visible;
    left: unset;
    max-width: unset;
  }

  .filter-fields-body .tag-block input[type="checkbox"]:not(:checked):hover {
    background-color: #fdf8b9;
  }
  .filter-fields-body
    .tag-block
    input[type="checkbox"]:not(:checked):hover
    + label {
    color: #9e4700;
  }

  .filter-fields-body .tag-block input[type="checkbox"]:checked {
    @apply bg-yellow;
  }
  .filter-fields-body .tag-block input[type="checkbox"]:checked + label {
    @apply text-black;
  }

  .filter-fields-body .tag-block input[type="checkbox"]::after,
  .filter-fields-body .tag-block input[type="checkbox"]:checked::after {
    content: unset;
    position: static;
    left: unset;
    top: unset;
    width: unset;
    height: unset;
  }

  .filter-fields-body .tag-block input[type="checkbox"]:active,
  .filter-fields-body .tag-block input[type="checkbox"]:focus,
  .filter-fields-body .tag-block input[type="checkbox"]:hover {
    @apply shadow-none;
  }

  .filter-fields-body .field:has(#id_in_person),
  .filter-fields-body .field:has(#id_remote) {
    @apply inline-block;
  }

  /* daterangepicker custom css */
  .daterangepicker td.active,
  .daterangepicker td.active:hover {
    @apply border-none bg-blue text-white;
  }
}

/* Filter results */
@layer components {
  .filter-result-row {
    @apply min-h-13 p-3 pb-0 relative flex flex-row flex-wrap cursor-pointer;
  }

  .filter-result-row:focus {
    @apply bg-yellow-rowhighlight;
  }

  .filter-result-row:not([aria-hidden]) .filter-result-row-divider {
    @apply flex order-10 m-3 mb-0 h-px bg-gray-border w-full;
  }

  [role="button"]:focus + .collapse-row[aria-hidden="false"] {
    @apply bg-yellow-rowhighlight;
  }

  .collapse-row[aria-hidden] {
    @apply min-h-0 p-0 border-transparent pointer-events-none;

    transition: 0.2s all ease-in-out;
  }

  .collapse-row[aria-hidden="true"] {
    @apply max-h-0 opacity-0 overflow-hidden;
  }

  .collapse-row[aria-hidden="false"] {
    @apply shadow-row max-h-250vh pointer-events-auto;
  }

  .search-highlights {
    overflow-wrap: anywhere;
  }

  .search-highlights em {
    @apply bg-yellow-highlight;

    font-style: normal;
  }

  .role-icon-link {
    @apply flex items-center justify-center relative h-44 w-44 rounded bg-yellow text-black;

    border: 3px solid transparent;
    font-size: 20px;
  }

  .role-icon-link:active,
  .role-icon-link:focus,
  .role-icon-link:hover {
    @apply border-gray-400;
  }

  .role-icon-link:active .icon-tooltip,
  .role-icon-link:focus .icon-tooltip,
  .role-icon-link:hover .icon-tooltip {
    @apply block;
  }

  .role-icon-link.Live-tweeting {
    @apply text-white;

    background-color: #1da1f2;
  }

  .role-icon-link .icon-tooltip {
    @apply absolute p-2 rounded bg-gray-dark text-white hidden whitespace-nowrap z-1;

    top: 48px;
    font-size: 13px;
  }

  .filter-result-status {
    @apply rounded-md px-4 py-3 text-sm;

    background-color: #f1f1f1;
    color: #595959;
  }

  .filter-result-status.draft,
  .filter-result-status.staging {
    background-color: #f1f1f1;
    color: #595959;
  }

  .filter-result-status.open {
    background-color: #eaf3ff;
    color: #0a60c2;
  }

  .filter-result-status.assigned {
    background-color: #fdf8b9;
    color: #9e4700;
  }

  .filter-result-status.review {
    background-color: #fde2b5;
    color: #673d01;
  }

  .filter-result-status.published,
  .filter-result-status.public {
    background-color: #d5f5d3;
    color: #005e31;
  }

  .filter-result-status.canceled {
    background-color: #fad9dd;
    color: #910012;
  }

  .filter-result-status.closed {
    @apply bg-gray-interactive text-white;
  }
}
