@layer components {
  .button {
    @apply h-10 leading-normal relative px-6 py-4 inline-flex flex-1 items-center justify-center rounded bg-gray-100 border-px border-gray-border cursor-pointer whitespace-nowrap text-center;
  }
  .button:hover,
  .button:active,
  .button:focus {
    @apply border-gray-borderdark;
  }

  /* Primary button */
  .button.primary {
    @apply border-none rounded-md bg-blue text-white font-bold;
  }
  .button.primary:hover {
    @apply bg-blue-100 text-blue;
  }
  .button.primary:focus {
    @apply bg-blue-200 text-blue;
  }

  /* Secondary button */
  .button.secondary {
    @apply border-px border-blue-200 rounded-md bg-blue-100 text-blue font-bold;
  }
  .button.secondary:hover,
  .button.secondary:focus {
    @apply bg-blue-200 text-blue;
  }

  /* Tetiary button */
  .button.tetiary {
    @apply border-none rounded-md bg-gray-bg text-gray-interactive font-bold;
  }
  .button.tetiary:hover {
    @apply bg-gray-bg text-blue;
  }
  .button.tetiary:focus {
    @apply bg-blue-200 text-blue;
  }

  a.button {
    @apply text-gray-dark;
  }
  a.button:hover,
  a.button:active,
  a.button:focus {
    @apply text-black;
  }

  .button.button-submit {
    @apply bg-blue text-white border-transparent;
  }
  .button.button-submit:hover,
  .button.button-submit:active,
  .button.button-submit:focus {
    @apply text-red border-black;
  }

  .button.button-submit[disabled],
  .button.button-submit[disabled]:hover,
  .button.button-submit[disabled]:focus {
    @apply bg-gray-dark text-white cursor-not-allowed;

    opacity: 0.5;
  }

  .button .icon:first-child:not(:last-child) {
    @apply m-0;
  }

  .delete {
    @apply appearance-none border-none rounded-full cursor-pointer pointer-events-auto inline-block grow-0 shrink-0 outline-none relative;

    font-size: 0;
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    max-height: 20px;
    max-width: 20px;
    vertical-align: top;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .delete::before,
  .delete::after {
    @apply block absolute bg-white;

    content: "";
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center;
  }

  .delete::before {
    height: 2px;
    width: 50%;
  }

  .delete::after {
    height: 50%;
    width: 2px;
  }

  .delete:hover,
  .delete:focus {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .delete:active {
    background-color: rgba(0, 0, 0, 0.4);
  }
}
